/*
* @subsection   Buttons
*/

$btn-xs-height: 40px;
$btn-xs-size: 16px;

$btn-sm-height: 50px;
$btn-sm-size: 18px;

$btn-md-height: 55px;
$btn-md-size: 18px;

$btn-lg-height: 63px;
$btn-lg-size: 18px;

$btn-xl-height: 65px;
$btn-xl-size: 18px;

$btn-group-offset: 30px;

// Button General Styles
.btn {
  display: inline-block;
  text-align: center;
  line-height: 1.2;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  text-decoration: none;
  letter-spacing: 0.1em;
  font-weight: 700;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  .icon {
    float: left;
    vertical-align: middle;
  }
}

// Button Layout Styles
.btn-xs {
  padding: 9px 55px 9px 15px;
  font-size: ceil($btn-xs-size);

  .icon {
    position: absolute;
    padding-left: 10px;
    top: 50%;
    @include transform(translateY(-50%));
    font-size: $btn-xs-size;
    line-height: (1em);
  }
}

.btn-sm {
  padding: 13px 50px 13px 13px;
  font-size: ceil($btn-sm-size);

  .icon {
    position: absolute;
    padding-left: 10px;
    top: 50%;
    @include transform(translateY(-50%));
    font-size: $btn-sm-size;
    line-height: (1em);
  }
}

.btn-md {
  padding: 15px 50px 15px 15px;
  font-size: ceil($btn-md-size);

  .icon {
    position: absolute;
    padding-left: 10px;
    top: 50%;
    @include transform(translateY(-50%));
    font-size: $btn-md-size;
    line-height: (1em);
  }
}

.btn-lg {
  padding: 20px 56px 20px 30px;
  font-size: ceil($btn-lg-size);
  .icon {
    position: absolute;
    padding-left: 10px;
    top: 50%;
    @include transform(translateY(-50%));
    font-size: $btn-lg-size;
    line-height: (1em);
  }
}

.btn-xl {
  padding: 22px 60px 22px 40px;
  font-size: ceil($btn-xl-size);
  .icon {
    position: absolute;
    padding-left: 10px;
    top: 50%;
    @include transform(translateY(-50%));
    font-size: $btn-xl-size;
    line-height: (1em);
  }
}

// Button Appearance Styles
.btn-default {
  color: $white;
  background-color: $content-color;

  &:hover {
    color: $white;
    background-color: $primary;
  }

  &:active {

  }
}

.btn {
  .icon {
    transition: .3s ease-out;
    @include opacity(1);
  }
  &:hover {
    .icon {
      @include transform(translate(10px, -50%));
      @include opacity(.75);
    }
  }
}

.btn-primary {
  color: $white;
  background-color: $primary;
  &:hover, &:active {
    color: $white;
    background-color: $secondary;
  }
}

.btn.fa-facebook {
  color: $white;
  background: #4569ae;
  border-color: #4569ae;
  box-shadow: $box-shadow-key-small;

  &:hover, &:active {
    background: darken(#4569ae, 5%);
    border-color: darken(#4569ae, 5%);
  }

  &:hover {
    box-shadow: $box-shadow-ambient;
  }

  &:active {
    box-shadow: $box-shadow-ambient;
  }
}

.btn.fa-twitter {
  color: $white;
  background: #5ea9dd;
  border-color: #5ea9dd;
  box-shadow: $box-shadow-key-small;

  &:hover, &:active {
    background: darken(#5ea9dd, 5%);
    border-color: darken(#5ea9dd, 5%);
  }

  &:hover {
    box-shadow: $box-shadow-ambient;
  }

  &:active {
    box-shadow: $box-shadow-ambient;
  }
}

.btn.fa-pinterest {
  color: $white;
  background: #cb2027;
  border-color: #cb2027;
  box-shadow: $box-shadow-key-small;

  &:hover, &:active {
    background: darken(#cb2027, 5%);
    border-color: darken(#cb2027, 5%);
  }

  &:hover {
    box-shadow: $box-shadow-ambient;
  }

  &:active {
    box-shadow: $box-shadow-ambient;
  }
}

// Button Group Styles
.btn-group {
  @include transform(translateY(-$btn-group-offset));
  margin-bottom: -$btn-group-offset;
  margin-left: -$btn-group-offset;

  .btn {
    margin-top: $btn-group-offset;
    margin-left: $btn-group-offset;
  }
}