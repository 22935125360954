/*
* @subsection Box
*/

$box-gutter: 15px;
$box-gutter-1: 6px;
$box-gutter-2: 10px;

.box__left {
  padding-bottom: $box-gutter;
}

.box__right {
  padding-top: $box-gutter;
}

.box-rtl {
  direction: rtl;

  > .box__left {
    padding-right: 0;
    padding-left: $box-gutter;
  }

  > .box__right {
    padding-right: $box-gutter;
    padding-left: 0;
  }
}

.box-ltr {
  direction: ltr;
}

.box-group, .box-group-outer > * {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.box {
  > .box__left img, > .box__right img {
    max-width: none;
  }

  > .box__left,
  > .box__right,
  > .box__body {
    padding: 0;
    direction: ltr;
    display: table-cell;
    vertical-align: top;
  }

  > .box__left {
    padding-right: $box-gutter;
    padding-bottom: 0;

    &--1 {
      padding-right: $box-gutter-1;
    }
    &--2 {
      padding-right: $box-gutter-2;
    }
  }

  > .box__right {
    padding-left: $box-gutter;
    padding-top: 0;
  }
}

@mixin make-box($resolution, $gutter) {
  .box-#{$resolution} {
    > .box__left img, > .box__right img {
      max-width: none;
    }

    > .box__left,
    > .box__right,
    > .box__body {
      display: table-cell;
      vertical-align: top;
    }

    > .box__left {
      padding-right: $gutter;
      padding-bottom: 0;

      &--1 {
        padding-right: $box-gutter-1;
      }
      &--2 {
        padding-right: $box-gutter-2;
      }

    }

    > .box__right {
      padding-left: $gutter;
      padding-top: 0;
    }
  }

  .box-#{$resolution}-clear {
    > .box__left img, > .box__right img {
      max-width: 100%;
    }

    > .box__left,
    > .box__right,
    .box__body {
      padding: 0;
      display: block;
      vert-align: top;
    }

    > .box__left {
      padding-bottom: $gutter;
    }

    > .box__right {
      padding-top: $gutter;
    }
  }

  .box-#{$resolution}-rtl {
    direction: rtl;

    > .box__left {
      padding-right: 0;
      padding-left: $box-gutter;
    }

    > .box__right {
      padding-right: $box-gutter;
      padding-left: 0;
    }
  }

  .box-#{$resolution}-ltr {
    direction: ltr;

    > .box__left {
      padding-right: $box-gutter;
      padding-left: 0;
    }

    > .box__right {
      padding-right: 0;
      padding-left: $box-gutter;
    }
  }
}

html:not(.lt-ie10) {
  @media (min-width: $screen-xs-min) {
    @include make-box("xs", $box-gutter);
  }

  @media (min-width: $screen-sm-min) {
    @include make-box("sm", $box-gutter);
  }

  @media (min-width: $screen-md-min) {
    @include make-box("md", $box-gutter);
  }

  @media (min-width: $screen-lg-min) {
    @include make-box("lg", $box-gutter);
  }
}

.box, .box-xs, .box-sm, .box-md, .box-lg {
  &, html:not(.lt-ie10) & {
    .box__middle {
      vertical-align: middle;
    }

    .box__bottom {
      vertical-align: bottom;
    }
  }
}