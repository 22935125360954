//========================================================
//                     Variables
//=========================================================

// Use relative measurements. Change to 0 on "Post Production"
$debug: 0;

// Layouts
$layout-boxed-width: 1920px;
$layout-boxed-bg: #444;


// Resolutions
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1600px;
$screen-xxl-min: 1800px;

// Fonts
$default-font-family: 'Montserrat', sans-serif;
$default-font-weight: 400;
$default-font-size: 18px;
$default-line-height: 28px;


// Anchor Colors
$default: #fff;
$contrast: #000;
$white: #fff;
$primary: #cda469;
$secondary: #527198;

// Relative Colors
$grayscale-darken: lighten($default, 20%);
$grayscale-default: lighten($default, 53.333333333333336%);
$grayscale-lighten: lighten($default, 92.15686274509804%);
$grayscale-lightest: lighten($default, 96.47058823529412%);

//ffd69c
$default-variant-1: #ffd69c;
//a2b3c8
$content-color: #a2b3c8;
//f5f5f7
$content-bg: darken(saturate(adjust_hue($default, 240), 11.111111111111093%), 3.529411764705884%);;

// Round Corvers
$border-radius-small: 4px;
$border-radius-base: 5px;
$border-radius-large: 8px;


// Shadows
$box-shadow-ambient: 0 0 0 rgba(0, 0, 0, .12);
$box-shadow-key-small: 3px 4px 5px 0px rgba(0, 0, 0, 0.11);
$box-shadow-key-light: 3px 4px 8px 0px rgba(0, 0, 0, 0.11);
$box-shadow-key-light-large: 8px 14px 18px 0px rgba(0, 0, 0, 0.11);


$column-count: 12;
$column-width: 70px;
$gutter-width: 30px;

$row-ws: $gutter-width;

$container-xs: 300px;
$container-sm: 720px + $gutter-width;
$container-md: 940px + $gutter-width;
$container-lg: $column-count*$column-width + $gutter-width*($column-count);

// .container {
//   @include make-container($gutter-width);
//   min-width: $container-xs;
//   max-width: $screen-xs-min;

//   @media (min-width: $screen-sm-min) {
//     max-width: $container-sm;
//   }
//   @media (min-width: $screen-md-min) {
//     max-width: $container-md;
//   }
//   @media (min-width: $screen-lg-min) {
//     max-width: $container-lg;
//   }
// }

// .container-fluid {
//   @include make-container($gutter-width * 2);
//   max-width: 2048px;
// }

// .row {
//   @include make-row($gutter-width);
// }

// [class*='col-'] {
//   @include make-col($gutter-width);
// }

// html.lt-ie10 * + [class*='col-'],
// html.lt-ie10 * + .row,
// * + [class*='col-'],
// * + .row {
//   margin-top: $row-ws;
// }

// html.lt-ie10 .row-no-gutter, .row-no-gutter {
//   margin-left: 0;
//   margin-right: 0;

//   > [class*='col'] {
//     padding-left: 0;
//     padding-right: 0;
//   }

//   > * + [class*='col'] {
//     margin-top: 0;
//   }
// }

// @media (min-width: $screen-xs-min) {
//   * + [class*='col-xs-'] {
//     margin-top: 0;
//   }

//   .row {
//     @include make-flex();
//     @include flex(0 1 auto);
//     @include flex-direction(row);
//     @include flex-wrap(wrap);
//   }

//   .row > [class*='col'] {
//     @include flex(0 0 auto);
//     @include flex-basis(100%);
//   }

//   @include make-grid-system("xs");
// }

// @media (min-width: $screen-sm-min) {
//   * + [class*='col-sm-'] {
//     margin-top: 0;
//   }

//   @include make-grid-system("sm");
// }

// @media (min-width: $screen-md-min) {
//   * + [class*='col-md-'] {
//     margin-top: 0;
//   }

//   @include make-grid-system("md");
// }

// @media (min-width: $screen-lg-min) {
//   * + [class*='col-lg-'] {
//     margin-top: 0;
//   }

//   @include make-grid-system("lg");
// }

// html.lt-ie10 {
//   .row > {
//     @include make-fallback($column-count);
//   }
// }