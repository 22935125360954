/*
* @subsection   Icons
*/

$icon-xs-size: 18px;
$icon-xs-fz: 18px;

$icon-sm-size: 30px;
$icon-sm-fz: 30px;

$icon-md-size: 35px;
$icon-md-fz: 35px;

$icon-lg-size: 48px;
$icon-lg-fz: 48px;

$icon-xl-size: 56px;
$icon-xl-fz: 56px;

// Icon General Styles
.icon {
  display: inline-block;
  text-align: center;
}


// Icon Sizing Styles
.icon-xs{
  width: $icon-xs-size;
  height: $icon-xs-size;
  line-height: $icon-xs-size - 4;
  font-size: $icon-xs-fz;
}

.icon-sm {
  width: $icon-sm-size;
  height: $icon-sm-size;
  line-height: $icon-sm-size;
  font-size: $icon-sm-fz;
}

.icon-md {
  width: $icon-md-size ;
  height: $icon-md-size ;
  line-height: $icon-md-size - 4 ;
  font-size: $icon-md-fz ;
  @media (min-width: $screen-md-min) {
    width: $icon-md-size;
    height: $icon-md-size;
    line-height: $icon-md-size - 4;
    font-size: $icon-md-fz;
  }
}

.icon-lg {
  width: $icon-lg-size - 14;
  height: $icon-lg-size - 14;
  line-height: $icon-lg-size - 4 - 14;
  font-size: $icon-lg-fz - 14;
  @media (min-width: $screen-md-min) {
    width: $icon-lg-size;
    height: $icon-lg-size;
    line-height: $icon-lg-size - 4;
    font-size: $icon-lg-fz;
  }
}

.icon-xl{
  width: $icon-xl-size;
  height: $icon-xl-size;
  line-height: $icon-xl-size - 4;
  font-size: $icon-xl-fz;
}


// Icon Appearance Styles
.icon-default {
  color: $content-color;
}

.icon-primary {
  color: $primary;
}

.icon-secondary {
  color: $secondary;
}


a {
  &.icon-default:hover {
    color: $primary;
  }

  &.icon-primary:hover {
    color: $secondary;
  }
}



