/*
* Static Layout
*/

$navbar-static-nav-indent: -5px;

$navbar-static-nav-color: $navbar-nav-color;
$navbar-static-nav-background: $navbar-nav-background;
$navbar-static-nav-hover-color: $navbar-nav-hover-color;
$navbar-static-nav-hover-background: $navbar-nav-hover-background;
$navbar-static-nav-active-color: $navbar-nav-active-color;
$navbar-static-nav-active-background: $navbar-nav-active-background;


.rd-navbar-static {
  display: block;

  .rd-navbar-nav-wrap {
    display: table-cell;
    vertical-align: middle;
  }

  .rd-navbar-nav-wrap {
    width: 100%;
    text-align: left;
  }

  .rd-navbar-nav{

    > li {
      display: inline-block;
      &:not(:first-child) {
        margin-left: $navbar-static-nav-indent;
      }
    }
  }

  // RD Navbar States
  .rd-navbar-static--visible{
    display: block;
  }

  .rd-navbar-static--hidden{
    display: none;
  }
}

