/* Do not edit main.css edit styles.css or main.scss insted */



/*
* @package      Style
* @description  This package holds all common style declarations
*               of the site
*/

@import "includes/variables.scss";
@import "includes/mixins.scss";
@import "includes/normalize.scss";
@import 'includes/social_menu';


/*
* @section      Main Styles
* @description  This section holds template default elements style declarations
*/

html {
    font-size: 60px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        font-size: 70px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
        font-size: 80px;
    }

    @media (min-width: $screen-lg-min) {
        font-size: 100px;
    }

    .old-ie {
        display: none;
    }

    &.lt-ie11 {
        .old-ie {
            display: block;
        }
    }
}

body {
    font-family: $default-font-family;
    font-weight: $default-font-weight;
    font-size: 16px;
    line-height: 1.7;
    -webkit-text-size-adjust: none;
    color: $content-color;

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
        font-size: 18px;
    }

    @media (min-width: $screen-md-min) {
        font-size: $default-font-size;
        line-height: $default-line-height;
    }
}


/*
* @subsection   Typography
* @description  Describes default font settings for basic html elements and
*               css classes
*/

h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
    display: block;
    color: $secondary;
    font-weight: 700;

    a:hover {
        color: $primary;
    }
}

h1,
.heading-1 {
    font-size: 38px;
    line-height: 1.33;

    @media (min-width: $screen-sm-min) {
        font-size: 62px;
        line-height: 1.16;
    }
    @media (min-width: $screen-md-min) {
        line-height: 1.2;
        font-size: 220px;
    }
}

h2,
.heading-2 {
    font-size: 34px;
    line-height: 1.5;

    @media (min-width: $screen-sm-min) {
        font-size: 52px;
        line-height: 1.32;
    }
    @media (min-width: $screen-md-min) {
        font-size: 60px;
        line-height: 1.2;
    }
    @media (min-width: $screen-lg-min) {
        font-size: 72px;
    }
}

h3,
.heading-3 {
    font-size: 30px;
    line-height: 1.5;

    @media (min-width: $screen-sm-min) {
        font-size: 30px;
        line-height: 1.37;
    }
    @media (min-width: $screen-md-min) {
        font-size: 36px;
        line-height: 48px;
    }
}

h4,
.heading-4 {
    font-size: 24px;

    @media (min-width: $screen-sm-min) {
        font-size: 24px;
        line-height: 1.47;
    }
    @media (min-width: $screen-md-min) {
        line-height: 36px;
    }
}

h5,
.heading-5 {
    font-size: 20px;

    @media (min-width: $screen-sm-min) {
        line-height: 1.66;
    }
    @media (min-width: $screen-md-min) {
        line-height: 30px;
    }
}

h6,
.heading-6 {
    font-size: 18px;

    @media (min-width: $screen-sm-min) {
        line-height: 1.5;
    }
    @media (min-width: $screen-md-min) {
        line-height: 28px;
    }
}

a {
    &.calendar {
        &:hover {
            color: $primary;
        }
    }
}

p,
.list,
.contact-info {
    a:hover {
        color: $primary;
    }
}

mark {
    display: inline-block;
    padding: 5px;
    color: #FFF;
    background: $primary;
}

em {
    color: $primary;
}

small,
.small {
    display: block;
    font-size: 0.875em;
    line-height: 1.643em;
}

.big {
    font-size: 1.2em;
}

time {
    &:before {
        padding-right: 8px;
    }
}

hr {
    height: 1px;
    width: 100%;
    display: none;
    padding: 0;
    background: rgba($default, 0.04);
}


/*
* @section      Main Layout
* @description  This section holds class declarations for elements indent creation
*/


/*
* @subsection   Offsets
* @description  Describes vertical indentation between the elements
*/

*+h1 {}

*+h2 {}

*+h3 {}

*+h4 {
    margin-top: 20px;
}

*+h5 {}

*+h6 {}

*+p {
    margin-top: 20px;
    @media (min-width: $screen-md-min) {
        margin-top: 37px;
    }
}

*+img {}

*+.btn {
    margin-top: 30px;
}

*+.link {}

*+.list {}

*+.index-list {}

*+.marked-list {}

*+.ordered-list {}

*+.inline-list {}

*+.contact-info {}

*+.btn-group {
    margin-top: 43px;
}

*+.box,
*+.box-xs,
*+.box-sm,
*+.box-md,
*+.box-lg {}

*+.terms-list {
    margin-top: 37px;
}

*+.row {}

*+.container {}

*+.container-fluid {
    margin-top: 66px;
}

*+.rd-mailform {
    margin-top: 49px;
}

*+time {}

p+p {
    margin-top: 3px;
}

.owl-carousel+.owl-carousel {
    margin-top: 42px;
}

.box,
.box-xs,
.box-sm,
.box-md,
.box-lg {
    &+.box,
    &+.box-xs,
    &+.box-sm,
    &+.box-md,
    &+.box-lg {}
}

.flow-offset-1 {
    @include make-flow-offset(70px);
}

.container {
    .rd-navbar-search-results {
        .result-item+.result-item {
            margin-top: 30px;
        }
    }
}

//offf
.offset {
    &-0 {
        margin-top: 0;
    }
    &-1 {
        @media (min-width: $screen-md-min) {
            margin-top: -794px;
        }
    }

    &-2 {
        @media (min-width: $screen-md-min) {
            margin-top: -10px;
        }
    }

    &-3 {
        @media (min-width: $screen-md-min) {
            margin-top: 242px;
        }
    }
    &-4 {
        @media (min-width: $screen-md-min) {
            margin-top: -150px;
        }
    }
    &-5 {
        @media (min-width: $screen-md-min) {
            margin-top: -114px;
        }
    }
    &-6 {
        @media (min-width: $screen-md-min) {
            margin-top: -44px;
        }
    }
    &-7 {
        @media (min-width: $screen-md-min) {
            margin-top: 38px;
        }
    }
    &-8 {
        margin-top: 48px;
    }
    &-9 {
        margin-top: 10px;
    }
    &-10 {
        margin-top: 15px;
    }
    &-11 {
        @media (min-width: $screen-md-min) {
            margin-top: 81px;
        }
    }
    &-12 {
        margin-top: 25px;
    }
    &-13 {
        margin-top: 87px;
    }
    &-14 {
        margin-top: 20px;
    }
    &-15 {
        margin-top: 62px !important;
    }
}


/*
* @subsection   Postfix
* @description  Describes instant right margin classes
*/

.postfix-1 {
    @media (min-width: $screen-md-min) {
        margin-right: -27px;
    }
}


/*
* @subsection   Prefix
* @description  Describes instant left margin classes
*/

.preffix-1 {
    @media (min-width: $screen-md-min) {
        margin-left: -40px;
    }
}

.preffix-2 {
    @media (min-width: $screen-md-min) {
        @media (min-width: $screen-md-min) {
            margin-left: 350px;
        }
        @media (min-width: $screen-lg-min) {
            margin-left: 104px;
        }
    }
}

.preffix-3 {
    margin-left: 4px;
}

.preffix-4 {
    margin-left: -11px;
}


/*
* @subsection   Insets
* @description  Describes instant padding classes
*/

.inset {
    &-1 {
        padding-top: 27px;
        padding-bottom: 27px;
        @media (min-width: $screen-md-min) {
            padding-left: 28px;
        }
    }

    &-2 {
        @media (min-width: $screen-md-min) {
            padding-right: 16%;
        }
    }

    &-3 {
        @media (min-width: $screen-md-min) {
            padding-right: 46%;
        }
    }
}


/*
* @subsection   Well
* @description  Describes section inner indents
*/

.well-xs {
    padding-top: 39px;
}

.well-sm {
    padding-top: 65px;
    &--inset-1 {
        padding-bottom: 76px;
    }
}

.well-md {
    padding-top: 76px;
}

.well-lg {
    padding-top: rem(87px);
}

.well-xl {
    padding-top: rem(95px);
}


/*
* @section      Helpers
* @description  This section holds various helper classes for
*               conditional page styling
*/

.round {
    border-radius: 50%;
}

.relative {
    position: relative;
}

.full-height {
    min-height: 100vh;

    @media(max-width: 767px){
        min-height: 80vh;
    }
}

.inline-block {
    display: inline-block;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.line-height {
    &-small {
        @media (min-width: $screen-md-min) {
            line-height: 30px;
        }
    }

    &-smaller {
        @media (min-width: $screen-md-min) {
            line-height: 20px;
        }
    }
}

.letter-spacing-negative-small {
    letter-spacing: -0.05em;
}

.letter-spacing-small {
    letter-spacing: 0.025em;
}

.letter-spacing-normal {
    letter-spacing: 0.075em;
}

.letter-spacing-big {
    letter-spacing: 0.22em;
}


/*
* @subsection   Colors
*/

html body .page {
    // Increases specificity to use with background helpers
    .text-primary {
        color: $primary;
    }

    a.text-primary {
        &:hover {
            color: $secondary;
        }
    }

    .text-default {
        color: $default;
    }

    .text-md-default {
        @media (min-width: $screen-md-min) {
            color: $default;
        }
    }
}


/*
* @subsection   Backgrounds
*/

html body .page {
    .bg-white {
        color: $content-color;
        background-color: $white;
        #{headings()} {
            color: $secondary;
        }

        &-md {
            background-color: transparent;
            @media (min-width: $screen-md-min) {
                background-color: $white;
            }
        }
    }

    .bg-primary {
        background: $primary;

        a:hover {
            color: $secondary;
        }
        .brand {
            &:before,
            &:after {
                background-color: $white;
            }
            &__name {
                &:hover {
                    color: inherit;
                }
            }
        }
        &,
        #{headings()} {
            color: $white;
        }
        .icon-primary {
            color: $white;
        }
        .btn-default {
            &:hover {
                color: $primary;
                background-color: $white;
            }
        }
        .btn-primary {
            color: $primary;
            background-color: $white;

            &:hover {
                color: $white;
                background-color: $secondary;
            }
        }
        p,
        .terms-list {
            color: $white;
            a:hover {
                color: $secondary;
            }
        }
        .list {
            a {
                color: $white;
                &:hover {
                    color: $secondary;
                }
            }
        }
        &.index-list-bg-absolute {
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100vw;
                background-color: $primary;
            }
            &:before {
                right: 100%;
            }
            &:after {
                left: 100%;
            }
        }
        .brand__slogan {
            color: $default-variant-1;
        }
        .text-primary {
            color: $secondary;
        }
        .index-list-1 {
            >li {
                >.index-list__counter {
                    &:before {
                        color: $white;
                    }
                }
            }
        }
        .marked-list {
            >li {
                >a {
                    color: $white;
                    &:before {
                        color: $white;
                    }
                }
            }
        }
        &.index-list {
            >li {
                >.index-list__body {
                    a:hover {
                        color: $default-variant-1;
                    }
                }
            }
        }
    }
}



/*
* @section      Icon Fonts
* @description  This section holds all icon fonts stored in template
*/

@import "components/_responsive-utilities";
@import "components/_fl-budicons-launch.scss";
@import "components/_fl-bigmug-line.scss";

/*
* @section      Components
* @description  This section holds all elements style declarations of template
*/

@import "components/_buttons.scss";
@import "components/_icons.scss";


/*
 * @subsection   Brand
 */

.brand {
    position: relative;
    
    @media ( max-width: 991px){
        text-align: center;
    }

    @media (min-width: $screen-md-min) {
        &:before, &:after {
            background: $white;
            content: '';
            position: absolute;
            height: 324px;
            width: 8px;
        }
        &:before {
            top: -255px;
            left: -20px;
            transform: rotate(45deg);
            transform-origin: 100% 100%;
        }
        &:after {
            bottom: -310px;
            right: 82px;
            transform: rotate(45deg);
            transform-origin: 0% 0%;
        }
    }

    &__name {
        z-index: 1;
        position: relative;
        left: -18px;
        display: inline-block;
        font-size: 75px;
        line-height: 1.16;
        letter-spacing: -0.05em;
        
        @media (min-width: $screen-md-min) {
            left: 28px;
            line-height: 1.2;
            font-size: 220px;
        }

        @media(max-width: 767px){
            left: 0;
        }

        &:before,
        &:after {
            position: absolute;
            font-size: 19px;
            letter-spacing: -0.025em;
            @media (min-width: $screen-md-min) {
                font-size: 72px;
            }
        }
    }

    &__slogan {
        margin-top: 0;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $default-variant-1;
        font-weight: 700;
        @media (min-width: $screen-md-min) {
            position: absolute;
            top: -20px;
            right: -15px;
        }

        @media ( max-width: 991px){
            margin-top: 15px;
        }
    }

    &--small {
        @media (min-width: $screen-md-min) {
            &:before, &:after {
                content: '';
                width: 5px;
                height: 189px;
            }
            &:before {
                top: -160px;
                left: -15px;
            }
            &:after {
            	bottom: -140px;
            	left: 300px;
                right: auto;
            }
        }

        .brand__name {

            @media (min-width: $screen-md-min) {
                font-size: 127px;
                left: 0;
                &:before,
                &:after {
                    @media (min-width: $screen-md-min) {
                        font-size: 42px;
                    }
                }
                &:before {
                    @media (min-width: $screen-md-min) {
                        top: 24%;
                        left: 103%;
                        right: auto;
                    }
                }
                &:after {
                    bottom: 13%;
                    left: 103%;
                    right: auto;
                }
            }
        }
        .brand__slogan {
            font-size: 11px;
            line-height: 16.08px;
            @media (min-width: $screen-md-min) {
                left: 161px;
                top: -15px;
            }
        }
    }
}


/*
* @subsection   Static Height
*/

.static-height {
    @media (min-width: $screen-md-min) {
        min-height: 1430px;
    }
}


/*
 * @subsection   Box
 */

@import "includes/tm-box/_boxes.scss";
@import "includes/tm-grid/_tm-grid.scss";

.box {}


/*
* @subsection   Quote
*/

.quote {
    .q {
        padding-top: 24px;
        position: relative;
        &:before,
        &:after {
            content: '';
            z-index: -1;
        }
        &:before {
            left: 50%;
            @include transform(translateX(-50%));
            position: absolute;
            top: 0;
            width: 80px;
            height: 57px;
            background: url("../images/quote__before.png") no-repeat;
            @media (min-width: $screen-md-min) {
                left: 0;
                @include transform(translateX(0%));
            }
        }
        &:after {

            display: none;

            @media (min-width: $screen-md-min) {
                display: inline-block;
            }

            margin-left: 8px;
            margin-top: 11px;

            width: 27px;
            height: 19px;
            background: url("../images/quote__after.png") no-repeat;
        }
    }

    .cite {
        margin-top: 38px;
        font-weight: 700;
        color: $secondary;
    }
}


/*
 * @subsection   Box Skin
 */

.box-skin {
    padding: 9.7% 15px 10.8%;
    @media (min-width: $screen-md-min) {
        padding: 75px 0 82px 37px;

        &--inset-1 {
            padding: 75px 0 271px 37px;
        }
    }

    *+.terms-list-1 {
        margin-top: 15px;
    }

    .offset-14+.terms-list-1 {
        margin-top: -1px;
    }

    *+.list {
        margin-top: 29px;
    }
}


/*
* @subsection   Circle
*/

.circle {

    display: inline-block;
    width: 12px;
    height: 12px;

    margin-top: 13px;
    margin-left: -5px;

    border: 3px solid $secondary;
    border-radius: 50%;

    @media (min-width: $screen-sm-min) {
        width: 14px;
        height: 14px;

        margin-top: 19px;
        margin-left: -13px;
    }
}


/*
* @subsection   Pricing Box
*/

.pricing-box {}


/*
* @subsection   Line
*/

.line {
    position: relative;
    @media (min-width: $screen-md-min) {
        &:before, &:after {
            content: '';
            position: absolute;
        }
        &:before {
            top: -269px;
            left: -3px;
            width: 8px;
            height: 324px;
            transform: rotate(45deg);
            transform-origin: 100% 100%;
        }
        &:after {
            bottom: -266px;
            right: -91px;
            width: 8px;
            height: 362px;
            transform: rotate(45deg);
            transform-origin: 0% 0%;
            @media (min-width: $screen-lg-min) {
                bottom: -262px;
                right: 22px;
            }
        }
    }

    &-white {
        &:before,
        &:after {
            background-color: $white;
        }
    }
}


/*
* @subsection   Breadcrumbs
*/

.breadcrumbs {}


/*
* @subsection   Banner
*/

.banner {
    position: relative;
    display: block;
    padding: 25px;
    @media (min-width: $screen-md-min) {
        margin-left: calc(#{- $container-md} / 12);
        margin-right: -$gutter-width;

        padding-top: 96px;
        padding-bottom: 95px;
        padding-left: calc(#{$container-md} / 12+#{$gutter-width} / 2);
        padding-right: $gutter-width/2;
    }
    @media (min-width: $screen-lg-min) {
        margin-left: calc(#{- $container-lg} / 12);
        padding-left: calc(#{$container-md} / 12+#{$gutter-width} / 2);
    }
    transition: .3s ease-out;

    &-md-offset {
        @media (min-width: $screen-md-min) {
            margin-top: -240px;
        }
    }
    *+h4 {
        margin-top: 4px;
    }
}

.banner.bg-primary {
    &:hover {
        background-color: darken($primary, 10%);
    }
}


/*
* @subsection  News Post
*/

.news-post {
    
    time {
        display: block;
    }

    *+time {
        margin-top: 32px;
    }

    *+p {
        margin-top: 25px;
    }
}


/*
* @subsection   List
*/

.list {

    a {
        font-weight: 700;
        color: $primary;
        &:hover {
            color: $secondary;
        }
    }

    li+li {
        margin-top: 23px;
    }
}


/*
* @subsection   Inline List
*/

.inline-list {
    @include transform(translateY(-7px));
    margin-bottom: -7px;
    margin-left: -7px;

    >li {
        display: inline-block;
        margin-top: 7px;
        margin-left: 7px;
    }
}


/*
* @subsection   Marked List
*/

.marked-list {
    text-align: left;

    >li {
        >a {
            position: relative;
            display: inline-block;

            font-size: 19px;
            font-weight: 700;

            color: $primary;

            @media (min-width: $screen-lg-min) {
                font-size: 24px;
            }
            &:before {
                position: absolute;
                content: '\e01b ';
                font-size: 17px;
                font-family: "fl-budicons-launch", sans-serif;
                color: $primary;
                top: 56%;
                right: -34px;
                @include transform(translate(0,
                -50%));
                transition: .3s ease-out all;
                cursor: default;
                pointer-events: none;
            }
        }

        &+li {
            margin-top: 32px;
        }

        >a:hover {
            color: $secondary;
            &:before {
                color: $secondary;
                right: -39px;
                @include opacity(0);
            }
        }
    }
}


/*
* @subsection   Index List
*/

.index-list {
    counter-reset: li;

    z-index: 1;
    position: relative;

    @media (min-width: $screen-md-min) {
        overflow: hidden;
    }

    margin-left: -15px;
    margin-right: -15px;

    padding: 74px 15px;

    @media (min-width: $screen-md-min) {
        margin-right: -30px;
        margin-left: 0;

        padding: 60px 0 137px;

        &--inset-1 {
            padding: 60px 0 51px;
        }
    }

    &:before,
    &:after {
        content: '';
    }
    >li {
        position: relative;

        padding-bottom: 31px;

        &:not(:last-child) {
            border-bottom: 1px solid rgba($default-variant-1, 0.3);
        }

        >.index-list__counter {
            font-size: 72px;
            line-height: 1;
            letter-spacing: -0.05em;

            color: $default-variant-1;
            @media (min-width: $screen-md-min) {
                position: absolute;
                top: -3px;
                left: -15px;
            }
            &:before {
                content: counter(li, decimal-leading-zero);
                counter-increment: li;
            }
        }

        >.index-list__body {
            margin-top: 15px;
            @media (min-width: $screen-md-min) {
                margin-top: 15px;
                margin-left: 98px;
                padding-right: 13%;
            }

            *+p {
                margin-top: 5px;
            }

            &--inset-1 {
                @media (min-width: $screen-md-min) {
                    padding-right: 8%;
                }
                @media (min-width: $screen-lg-min) {
                    padding-right: 13%;
                }
            }

            &--inset-2 {
                @media (min-width: $screen-md-min) {
                    padding-right: 6%;
                }
            }
        }
    }

    *+h4 {
        margin-top: 0;
    } // Sets offset between list rows
    @include make-flow-offset(31px);
}

.index-list-1 {

    counter-reset: li;

    >li {
        >.index-list__counter {

            &:before {
                content: counter(li, decimal-leading-zero) '/.';
                counter-increment: li;
                font-size: 24px;
                color: $primary;
                margin-right: 5px;
            }
        }
    }
}


/*
* @subsection   Ordered List
*/

.ordered-list {
    >li {
        padding-left: 20px;
        list-style-type: decimal;
    }
}


/*
* @subsection   Terms  List
*/

.terms-list {
    letter-spacing: -0.01em;
    >dt+dd {
        margin-top: 24px;
    }

    >dd+dt {
        margin-top: 35px;
    }
}

.terms-list-1 {
    dt,
    dd {
        display: inline-block;
    }
    dt {
        min-width: 229px;

        &:after {
            content: ':';
        }
    }
    .heading-6 {
        @media (max-width: $screen-xs-min) {
            font-size: 16px;
        }
    }
    &--table {
        dt {
            min-width: 0;
        }
        dd {
            float: right;
        }
    }
}


/*
* @subsection   Contact Info
*/

.contact-info {
    p {}

    dl {
        dt,
        dd {
            display: inline-block;
        }

        dt:after {
            content: ':';
        }

        dt {
            min-width: 80px;
        }
    }
}


/*
* @subsection   Counter
*/

.counter {}


/*
* @subsection   Image Wrap
*/

.image-wrap {

    overflow: hidden;

    @media (max-width: $screen-md-min - 1) {
        margin-top: 20px;
    }

    &-ofset-1 {
        margin-top: 35px;
        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
    }

    &:before {
        @media (min-width: $screen-md-min) {
            z-index: 1;
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            left: 0;
            width: 100%;
            background: -moz-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.38) 35%, rgba(255, 255, 255, 0) 56%, rgba(255, 255, 255, 0) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.38) 35%, rgba(255, 255, 255, 0) 56%, rgba(255, 255, 255, 0) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.38) 35%, rgba(255, 255, 255, 0) 56%, rgba(255, 255, 255, 0) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
            /* IE6-9 fallback on horizontal gradient */
        }
    }

    &--gradient-1 {
        &:before {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+96&1+0,0+10 */
            background: -moz-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 96%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 96%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 96%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
            /* IE6-9 fallback on horizontal gradient */
        }
    }
    @media (min-width: $screen-md-min) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - #{$container-md} / 2 + #{$gutter-width} / 2 + (#{$container-md} / #{$column-count}) * 5 - 0.1px);
        right: 0;

        @media (min-width: $screen-lg-min) {
            left: calc(50% - #{$container-lg} / 2 + #{$gutter-width} / 2 + (#{$container-lg} / #{$column-count}) * 5 - 0.1px);
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            max-width: none;
        }
    }

    @media (min-width: 2051px) {
        img {
            width: 100%;
            height: auto;
        }
    }
}


/*
* @subsection   Background Image
*/

.bg-image {
    position: absolute;

    &-base {
        background-repeat: no-repeat;
        -webkit-background-size: 231%;
        background-size: 231%;
        @media (min-width: $screen-md-min) {
            background-size: cover;
        }
    }

    &-1 {
        top: 26%;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../images/page-01_img01.png");
        @media (min-width: $screen-sm-min) {
            top: -4%;
        }
        @media (min-width: $screen-md-min) {
            top: -9%;
            background-position: 34% 0;
        }
    }

    &-2 {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../images/page-02_img07.png");
        background-position: 25% bottom;
        @media (min-width: $screen-md-min) {
            background-position: left bottom;
        }
    }
}


/*
* @subsection   Background Absolute
*/

.bg-absolute {
    position: absolute;

    &-position-1 {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @media (min-width: $screen-md-min) {
            left: calc(50% - #{$container-md} / 2 + #{$gutter-width} / 2 + (#{$container-md} / #{$column-count}) * 7);
        }
        @media (min-width: $screen-lg-min) {
            left: calc(50% - #{$container-lg} / 2+#{$gutter-width} / 2+(#{$container-lg} / #{$column-count}) * 8);
        }
    }
    &-position-2 {
        @media (min-width: $screen-md-min) {
            top: 0;
            left: 100%;
            height: 100%;
            width: 100vw;
        }
    }
    &-position-3 {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @media (min-width: $screen-xs-min) {
            left: calc(50% - #{$container-xs*1.3});
            right: calc(50% - #{$container-xs*1.3});
        }
        @media (min-width: $screen-sm-min) {
            left: calc(50% - #{$container-sm});
            right: calc(50% - #{$container-sm});
        }
        @media (min-width: $screen-md-min) {
            left: calc(-#{$container-md} / 12 * 1 - #{$gutter-width} / 2);
            right: calc(-#{$container-md} / 12 * 1 - #{$gutter-width} / 2);
        }
        @media (min-width: $screen-lg-min) {
            left: calc(-#{$container-lg} / 12 * 1+#{$gutter-width} / 2);
            right: calc(-#{$container-lg} / 12 * 1 - #{$gutter-width} / 2);
        }
    }
    &-image-1 {
        background-image: url(../images/page-01_img02.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
    &-image-2 {
        background-image: url(../images/page-04_img21.jpg);
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
    }

    &-white {
        background-color: $white;
    }
}


/*
* @subsection   Background Fixed
*/

.bg-fixed {}


/*
* @subsection   Thumb
*/

.thumb {
    position: relative;
    display: block;
    overflow: hidden;

    >img {
        width: 100%;
    }

    &__overlay {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba($grayscale-darken, .5);
        opacity: 0;
        text-align: center;
        transition: .3s all ease;

        >* {
            vertical-align: middle;
            @include transform(translateY(30px));
            transition: .3s all ease;
        }

        &:before {
            content: '';
            display: inline-block;
            height: 50%;
        }
    }

    &:hover {
        .thumb__overlay {
            opacity: 1;

            >* {
                @include transform(translateY(0));
            }
        }
    }
}


/*
* @subsection   Link
*/

.link {
    display: inline-block;
}


/*
* @subsection   Video
*/

.video {
    position: relative;
    padding-bottom: percentage(340 / 570); // percentage( Height / Width ) on PSD
    >iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}


/*
* @section      Extensions
* @description  This section holds some of style declarations for necessary
*               scripts
*/

@import "includes/_rd-navbar.scss";



/*
* @section      Page Header
* @description  This section holds specific style redeclarations for some
*               of common elements in page header
*/

.page-header {
    background-color: $white;

    &__body {

        background-color: $primary;

        padding-top: 30px;
        @media (min-width: $screen-xs-min) {
            padding-top: 60px;
        }
        @media (min-width: $screen-md-min) {
            padding-top: 101px;
        }

        &--inset-1 {
            padding-top: 57px;
            padding-bottom: 109px;
        }
    }
}


/*
* @section      Page Content
* @description  This section holds specific style redeclarations for some
*               of common elements in page content
*/

.page-content {
    .desktop & {
        min-height: 100vh;
    }
    &--inset-1 {
        .desktop & {
            min-height: 0;
        }
    }
}


/*
* @section      Page Footer
* @description  This section holds specific style redeclarations for some
*               of common elements in page footer
*/

.page-footer {
    padding-top: 72px;
    padding-bottom: rem(87px);
    background-color: $white;

    @media(max-width: 991px){
        text-align: center;

        * + [class*='col-'] {
            margin-top: 30px;
        }
    }

    


    .brand {
        margin-top: -7px;
        &:before,
        &:after {
            display: none;
        }
        .brand__name {
            left: -11px;
            font-size: 72px;
            @media (min-width: $screen-md-min) {
                left: 0;
            }
            &:before,
            &:after {
                font-size: 18px;
                font-weight: 700;
            }
            &:before {
                top: 28px;
                left: 104%;
                right: auto;
            }
            &:after {
                top: auto;
                bottom: 13px;
                left: 104%;
                right: auto;
            }
        }
    }

    .contact-info {
        font-size: 18px;
        font-weight: 700;
        a[href^="callto"] {
            display: inline-block;
            margin-top: 21px;
            font-size: 24px;
            letter-spacing: 0.025em;
        }
    }

    .inline-list {
        margin-top: 9px;
    }
}

.copyright {
    z-index: 10;
    position: relative;
    margin-top: 0;
    font-size: 14px;
}

.elementor-widget-widget-list {
	&.elementor-position{
		&-right {
            .list-box-wrapper {
                .icon-list {
                    li {
                        display: flex;
                        
                        a {
                           order: 1; 
                        }

                        i {
                            order: 2;
                            margin-left: 15px;
                        }
                    }
                }
            }
		}

		&-left {
			.list-box-wrapper {
                .icon-list {
                    li {
                        display: flex;

                        a {
                           order: 2; 
                        }

                        i {
                            order: 1;
                            margin-right: 15px;
                        }
                    }
                }
            }
		}
	}

    &.list-1 {
        .list-box-wrapper {
            .icon-list {
                li {
                    i {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 30px;
                        color: #527198 !important;
                    }

                    &:hover {
                        i {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .list-box-wrapper {
        .icon-list {
            li {
                display: flex;

                
                a {
                    
                }

                i {
                    opacity: 1;
                    transition: .3s ease-out all;
                    cursor: default;
                    pointer-events: none;
                    font-size: 17px;
                    font-weight: 700;

                }

                &:hover {
                    i {
                        opacity: 0;
                    }
                }


                + li {
                    margin-top: 32px;
                }
            }
        }
    }
}

.bg-white {
    background: $white;
}

.text-regular {
    font-weight: 400;
}

.bg-primary {
    background: $primary;
}

.text-md-default {
    @media(min-width: 992px){
        color: $white;
        text-align: left;
    }
}

.text-primary {
    color: $primary;
}

.text-uppercase {
    text-transform: uppercase;
}

.elementor-widget-widget-counter-content {
    ol {
        li {
            a {
                color: $white;

                &:hover {
                    color: $default-variant-1;
                }
            }
            h1,h2,h3,h4,h5,h6,
            .heading-1,
            .heading-2,
            .heading-3,
            .heading-4,
            .heading-5,
            .heading-6 {
                color: $white;
            }
        }
    }
}

/*
 * Owl Navigation
 */

.owl-block {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  height: 315px;
  width: 300px;
  padding: 75px 35px 20px 35px;
  text-align: left;
  color: #fff;

  @media(max-width: 767px){
    position: relative;
    width: 100%;
  }

  // @media (min-width: $screen-sm-min) {
  //   width: 400px;
  // }

  @media (min-width: $screen-md-min) {
    width: 501px;
    height: 366px;
    padding: 132px 75px 20px 98px;
  }

  * + h4 {
    margin-top: 6px;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #fff;
  }
}

.carousel-counter-container {
  z-index: 1;
  position: absolute;

  left: 65px;
  bottom: 246px;

  @media(max-width: 767px){
    display: none;
  }

  @media(min-width: 768px) and (max-width: 991px){
    left: 75px;
    bottom: 235px;
  }

  @media (min-width: $screen-md-min) {
    left: 140px;
    bottom: 36%;
  }

  .swiper-pagination-custom {
    align-items: center;
    display: flex;
  }
}

.current-counter, .carousel-count, .count {
  font-weight: 700;
  float: left;
  font-size: 24px;
  color: $default-variant-1;
}

.count, .carousel-count {
  color: $white;
}

.count {
  margin-left: 5px;
}

.carousel-count {
  margin-left: 7px;
}


.nav-controls {
  position: absolute;

  
  @media(max-width: 767px){
    position: absolute;
    top: 50%;
    width: 100%;
  }

  @media(min-width: 768px) and (max-width: 991px){
    bottom: 267px;
    left: 35px;
    width: 123px;
  }

  @media (min-width: $screen-md-min) {
    bottom: 40%;
    left: 101px;
    width: 123px;
  }

  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    font: 400 18px/1 'fl-budicons-launch';
    color: $default-variant-1;
    background-image: none;
    margin: 0;
    width: auto;
    height: auto;

    transition: .3s ease-out all;
    &:hover {
      color: $white;
    }
  }

  .swiper-button-prev {
    left: 0;
    &:before {
      content: '\e00e ';
    }
  }

  .swiper-button-next {
    right: 0;
    &:before {
      content: '\e01b';
    }
  }
}

.swiper-slide-offset {
    padding-bottom: 118px;
    position: relative;

    .btn-primary {
        position: absolute;
        bottom: 25px;

        @media(min-width: 768px) and (max-width: 991px){
            right: 0;
        }
    }
}

.swiper-bg {
    position: relative;
    background: #fff;

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      width: 100vw;
      background-color: $white;

    }
    img {
        // @media(max-width: 767px){
        //     max-width: none;
        //     width: auto;
        // }
        width: 100%;
    }


}

.site-main {
    overflow-x: hidden;
}

.wpcf7 {
    .wpcf7-form {
        .wpcf7-form-control-wrap {
            margin-bottom: 30px;
        }

        input[type="text"],
        input[type="tel"],
        input[type="url"],
        input[type="email"],
        input[type="date"],
        textarea {
            display: block;
            width: 100%;
            margin: 0;
            -webkit-appearance: none;
            font-size: 18px;
            padding: 13px 18px;
            padding-top: 11.2px;
            padding-bottom: 14.8px;
            line-height: 34px;
            font-weight: 400;
            color: #a2b3c8;
            background: #fff;
        }

        input[type="text"],
        input[type="tel"],
        input[type="url"],
        input[type="email"],
        input[type="date"] {
            height: 60px;
        }

        textarea {
            height: 197px;
        }

        .submit-wrap {
            input[type="submit"] {
                display: none;
            }
        }
    }
}

.hide_mobile {
    @media(max-width: 767px){
        display: none;
    }
}

.hide_tablet {
    @media(min-width: 768px) and (max-width: 1024px){
        display: none;
    }
}

.hide_desktop {
    @media(min-width: 1025px){
        display: none;
    }
}

.block-counter {
    .index-list__counter .elementor-heading-title {
        &:before {
            content: counter(li, decimal-leading-zero) '/.';
            counter-increment: li;
            font-size: 24px;
            color: $primary;
            margin-right: 5px;
        }
    }
}

body.blog,
body.search,
body.archive,
body.single {
    #content {
        padding-top: 65px;
        padding-bottom: 65px;
    }
}

.post-holder {
    margin-bottom: 1.5em;
}

#sidebar {

    .widget {
        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 1em;
        }
    }

    ul {
        li {
            padding-left: 15px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 2px;
                top: 13px;
                transform: translateY(-50%);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $primary;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

ul.related-posts {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    li {
        width: 25%;
        flex-basis: 25%;
        padding: 0 15px;
        
        figure {
            margin-bottom: 15px;
        }

        img {
            width: 100%;
        }   

        @media(max-width: 767px){
            width: 50%;
            flex-basis: 50%;
        }
    }
}

.related-posts-wrap {
    h5.entry-title {
        font-size: 30px;
        line-height: 40px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}


#comments {
    #respond {
        #reply-title {
            font-size: 30px;
            line-height: 40px;
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
}

#comments {
    margin-top: 35px;
}


#commentform {
    label {
        display: block;
        margin-bottom: 5px;
    }

    > p {
        margin-bottom: 15px;
    }

    label[for*='wp-comment-cookies-consent']{
        display: inline-block;
        line-height: 30px;
        margin-bottom: 0;
        margin-left: 5px;
    }

    input[type="text"],
    input[type="email"],
    input[type="url"],
    textarea {
        width: 100%;
        height: 50px;
        background: #fff;
        padding: 10px;
    }

    textarea {
        height: 150px;
    }

    .form-submit {
        
        margin-top: 15px;

        input[type='submit'] {
            color: #fff;
            background-color: $primary;
            border-color: $primary;
            padding: 12px 22px;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 0.9px;
            font-weight: 700;
            border: none;

            &:hover {
                background: $secondary;
            }
        }
    }
}

form.search {
    position: relative;

    input[type="text"] {
        width: 100%;
        font-size: 18px;
        padding: 19px 10px;
        line-height: 49px;
        color: #bbc2c7;
        font-weight: 500;
        background: #fff;
        border: 1px solid #e5e9eb;
        border-radius: 0px;
        height: 49px;
    }

    .search-btn {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        background: $primary;
        width: 49px;
        padding: 0;
        height: 49px;
        text-align: center;
        line-height: 49px;
        cursor: pointer;

        i {
            display: inline-block;
            vertical-align: middle;

        }

        &:hover {
            background: $secondary;
        }
    }
}

ol.comment-list {
    > li {
        border: 1px solid;
    }
}

#error404 {
    padding: 50px 0
}

.page-title {
    margin-bottom: 20px;
}

.title-wrapper {
    width: 100%;
}

.custom_posts_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &:not(.owl-carousel){
        
        margin: 0 -15px;

        .custom_posts_item {
            margin-bottom: 35px;
        }
    }
}

.custom_posts_wrapper {
    &.owl-carousel{
        div[class*="col-desk"] {
            padding-left: 0;
            padding-right: 0;
        }        
    }
}



.custom_posts_wrapper div[class*="col-desk"] {
    padding-top: 0;
    padding-bottom: 0;
}

.col-desk-1,
.col-desk-2,
.col-desk-3,
.col-desk-4,
.col-desk-5,
.col-desk-6,
.col-tab-1,
.col-tab-2,
.col-tab-3,
.col-tab-4,
.col-tab-5,
.col-tab-6,
.col-mob-1,
.col-mob-2,
.col-mob-3,
.col-mob-4,
.col-mob-5,
.col-mob-6 {
    position: relative;
    min-height: 1px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.col-mob-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.col-mob-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-mob-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-mob-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-mob-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.col-mob-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66666%;
    -ms-flex: 0 0 16.66666%;
    flex: 0 0 16.66666%;
    max-width: 16.66666%;
}

@media (min-width: 768px) {
    .col-tab-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-tab-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-tab-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-tab-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-tab-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-tab-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66666%;
        -ms-flex: 0 0 16.66666%;
        flex: 0 0 16.66666%;
        max-width: 16.66666%;
    }
}

@media (min-width: 1025px) {
    .col-desk-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-desk-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-desk-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-desk-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-desk-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-desk-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66666%;
        -ms-flex: 0 0 16.66666%;
        flex: 0 0 16.66666%;
        max-width: 16.66666%;
    }
}

.custom_posts_wrapper.owl-carousel {
    position: relative;
    .owl-nav {
        .owl-prev,
        .owl-next {
            width: 40px;
            position: absolute;
            top: 50%;
            cursor: pointer;
            color: inherit;
            &:hover {
                color: green;
            }
            &::before {
                font-family: "FontAwesome";
                font-size: 40px;
            }
        }
        .owl-prev {
            left: -40px;
            &::before {
                content: '\f104';
            }
        }
        .owl-next {
            right: -40px;
            &::before {
                content: '\f105';
            }
        }
    }
    .owl-dots {
        text-align: center;
        .owl-dot {
            display: inline-block;
            margin: 0 5px;
            span {
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: rgba(0,0,0,0.3);
                transition: opacity .3s ease;
            }
            &.active,
            &:hover {
                span {
                    background: rgba(0,0,0,0.6);
                }
            }
        }
    }
}

.elementor-widget-wp-widget-archives {
    h5 {
        display: none;
    }

    ul {
        li {
            a {
                position: relative;
                display: inline-block;
                font-size: 19px;
                font-weight: 700;
                color: $primary;

                @media(min-width: 1200px){
                    font-size: 24px;
                }

                &:before {
                    position: absolute;
                    content: '\e01b ';
                    font-size: 17px;
                    font-family: "fl-budicons-launch", sans-serif;
                    color: $primary;
                    top: 56%;
                    right: -34px;
                    -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    transition: .3s ease-out all;
                    cursor: default;
                    pointer-events: none;
                }

                &:hover {
                    &:before {
                        color: $secondary;
                        right: -39px;
                        opacity: 0;
                    }
                }
            }
        }
    }
}

.text-bold, strong {
    font-weight: 700;
}

.pagenavi.nav-links {
    .btn {
        
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: $primary;
        color: $white;
        margin-top: 0;

        &:hover,
        &.current {
            background: $secondary;
        }

        + .btn {
            margin-left: 10px;
        }
    }
    
}

.blog-image {
    img {
        width: 100%;
    }
}

h4.entry-title {
    margin-top: 1em;
}

.tm-testi__wrap {
    .tm-testi__list {
        .tm-testi__item {
            min-height: auto;
            height: auto;

            .tm-testi-slider__item blockquote {
                text-align: left;
            }
        }
    }

    .tm-testi-slider__pags {

        .swiper-pagination-bullet {

            border-radius: 50%;
            position: relative;
            margin-left: 10px;
            width: 16px;
            height: 16px;
            outline: none;
            cursor: pointer;
            background-color: transparent;
            box-shadow: inset 0 0 0 2px $primary;
            transition: box-shadow 0.3s ease;
            opacity: 1;

            &.swiper-pagination-bullet-active,
            &:hover {
                box-shadow: inset 0 0 0 8px $primary;
            }    
        }
        
    }
}

.text-center {
    text-align: center;
    @media(min-width: 992px){
        text-align: left;
    }
}

a {
    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
}

.elementor-widget-swiper-module {
    .swiper-wrapper {
        @media(max-width: 767px){
            .swiper-slide {
                flex-direction: column;
            }
        }
    }
}

.elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
    @media(min-width: 768px) and (max-width: 1199px){
        flex-direction: column !important;
    }

    @media(max-width: 1199px){
        .elementor-image-box-img img {
            display: inline-block;
        }
    }
}

.navigation.post-navigation {
    width: 100%;
    display: block;
}